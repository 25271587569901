import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Project from '../views/Project.vue';
import About from '../views/About.vue';
import News from '../views/News.vue';
import Article from '../views/Article.vue'
import Detail from '../views/Detail.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/project',
    name: 'Project',
    component: Project,
  },
  {
    path: '/news',
    name: 'News',
    component: News,
  },
  // {
  //   path: '/map',
  //   name: 'Map',
  //   component: Map,
  // },
  {
    path: '/article',
    name: 'Article',
    component: Article,
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
