<template>
  <div class="scrollContainer">
    <Header />
    <div class="secondBlock">
      <div class="secondTitle">
        品牌項目<img class="titleBack" src="@/assets/titleBack.png" alt="" />
      </div>
      <div class="projectList">
        <div
          v-for="(item, index) in projectList"
          :key="index"
          class="projectItem"
          @click="openUrl(item)"
        >
          <div class="projectImg"><img :src="item.image" alt="" /></div>
          <div class="projectTitle">
            <div class="titleText">{{ item.title }}</div>
            <img src="@/assets/rightIcon_grey.png" alt="" />
          </div>
        </div>
        <!-- <div class="projectItem blankItem"></div> -->
      </div>
      <div></div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      projectList: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    document.title = "品牌項目 - 薈翔 Teglidan";
    this.getProjectList();
  },
  methods: {
    openUrl(item) {
      if (item.isLink) {
        window.open(item.linkUrl);
      }
      else window.open(`/detail?type=project&id=${item.id}`)
    },
    async getProjectList() {
      const projectRes = await request.get("/webapi/web/brandProject/list", {});
      this.projectList = projectRes.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.isMobile {
  .secondBlock {
    padding-top: 100px;
    .projectList {
      margin: 0 auto 60px auto;
      .projectItem.blankItem {
        display: none;
      }
      .projectItem {
        width: 310px;
        height: 310px;
        margin: 0 auto 24px auto;
        .projectImg {
          width: 100%;
          height: 79%;
        }
        .projectTitle {
          font-size: 14px;
          height: 60px;
          line-height: 1.4;
          .titleText {
            max-width: 220px;
            margin-right: 6px;
          }
          img {
            width: 14px;
          }
        }
      }
    }
  }
}
.secondBlock {
  .projectList {
    display: flex;
    flex-wrap: wrap;
    width: 99%;
    margin: 0 auto 120px auto;
    max-width: 1260px;
    .projectItem.blankItem:hover {
      box-shadow: none;
    }
    .projectItem.blankItem {
      cursor: auto;
    }
    .projectItem:hover {
      box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.1);
    }
    .projectItem {
      width: 376px;
      height: 375px;
      margin: 0 18px 33px 18px;
      cursor: pointer;
      .projectImg {
        width: 376px;
        height: 296px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .projectTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        height: 90px;
        .titleText {
          max-width: 290px;
          margin-right: 20px;
        }
        img {
          width: 24px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.isMobile {
  .secondBlock {
    padding-top: 80px;
    background: none;
  }
  .secondTitle {
    font-size: 24px;
    margin-bottom: 50px;
    text-align: center;
    .titleBack {
      width: 68px;
    }
  }
}
.secondBlock {
  padding-top: 190px;
  text-align: center;
  min-height: 65vh;
  background: url("~@/assets/secondBack.png");
  background-size: cover cover;
  background-repeat: no-repeat;
  background-position-y: 90px;
  background-position-x: 50%;
}
.secondTitle {
  color: #323232;
  font-size: 60px;
  font-weight: 600;
  position: relative;
  margin-bottom: 100px;
  text-align: center;
  .titleBack {
    position: absolute;
    width: 152px;
    left: 50%;
    top: -50%;
    transform: translate(-50%);
  }
}
</style>