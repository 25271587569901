<template>
  <div>
    <Header />
    <div class="container">
      <div class="title">{{ detail.title }}</div>
      <div class="meta">
        <div class="authorList" v-if="detail.authorList">
          <span v-for="(item, index) in detail.authorList" :key="index">{{
            item.name
          }}</span>
        </div>
        <div class="date">{{ detail.publishDate }}</div>
      </div>
      <div class="devider"></div>
      <div class="content" v-html="detail.content"></div>
      <!-- <div class="content">
        <p>
          融合创新，同向未来！9月1日下午，备受腸目的香港科技大学（广州）开学典礼在学校体育馆隆重举行。香港科技大学（广州）是《粤港澳大汽区发展规划纲要〉预布实施以来获批设立的第一所内地与香港合作大学，也是《广州南沙深化面向世界的粤港澳全面合作总体方案〉出台后落成的首个重大项目。这所大学打破传统高校的“院系”之分，采用全新的”枢纽”和”学域”学术架构，致力于成为全世界第一家融合学科大学，将为世界高等教育改革探路。
        </p>
        <img src="@/assets/logo.png" alt="" />
        <p>
          融合创新，同向未来！9月1日下午，备受腸目的香港科技大学（广州）开学典礼在学校体育馆隆重举行。香港科技大学（广州）是《粤港澳大汽区发展规划纲要〉预布实施以来获批设立的第一所内地与香港合作大学，也是《广州南沙深化面向世界的粤港澳全面合作总体方案〉出台后落成的首个重大项目。这所大学打破传统高校的“院系”之分，采用全新的”枢纽”和”学域”学术架构，致力于成为全世界第一家融合学科大学，将为世界高等教育改革探路。
        </p>
      </div> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      type: "",
      id: "",
      detail: {},
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    getDetail(type, id) {
      if (type == "project") {
        this.getProjectDetail(id);
      }
      if (type == "news") {
        this.getNewsDetail(id);
      }
      if (type == "article") {
        this.getArticleDetail(id);
      }
    },
    async getProjectDetail(id) {
      const projectRes = await request.get("/webapi/web/brandProject/detail", {
        params: {
          id: id,
        },
      });
      this.detail = projectRes.data;
    },
    async getNewsDetail(id) {
      const newsRes = await request.get("/webapi/web/information/detail", {
        params: {
          id: id,
        },
      });
      this.detail = newsRes.data;
    },
    async getArticleDetail(id) {
      const articleRes = await request.get("/webapi/web/article/detail", {
        params: {
          id: id,
        },
      });
      this.detail = articleRes.data;
      document.title = `${this.detail.title} - 薈翔 Teglidan`;
    },
  },
  async created() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    this.getDetail(this.type, this.id);
  },
};
</script>
<style lang="scss">
.isMobile {
  .container {
    margin: 50px auto 0 auto;
    padding-top: 20px;
    .title {
      font-size: 20px;
      margin-bottom: 18px;
    }
    .meta {
    color: #666;
    font-size: 16px;
  }
  .authorList {
    margin-bottom: 10px;
    span {
      margin-right: 4px;
    }
  }
  .devider {
    margin: 24px 0;
  }
  .content {
    font-size: 16px;
    padding-bottom: 60px;
    img {
      margin: 8px 0;
    }
  }
  }
}
.container {
  width: 90%;
  max-width: 1200px;
  margin: 90px auto 0 auto;
  padding-top: 107px;
  min-height: 65vh;
  .title {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 34px;
    line-height: 1.3;
  }
  .meta {
    color: #666;
    font-size: 20px;
  }
  .authorList {
    margin-bottom: 22px;
    span {
      margin-right: 6px;
    }
  }
  .devider {
    width: 100%;
    height: 1px;
    background: #e0e0e0;
    margin: 40px 0;
  }
  .content {
    line-height: 1.5;
    padding-bottom: 120px;
    font-size: 20px;
    color: #323232;
    img {
      width: 100%;
      margin: 16px 0;
    }
    p {
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
  }
}
</style>