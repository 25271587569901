<template>
  <div class="scrollContainer">
    <Header />
    <div class="headImg">
      <img src="https://teglidan.wareton.com/webimg/headImg.png" alt="" />
      <div class="headFilter"></div>
      <div class="headText">
        薈翔願成為<br />青年朋友的知心人、青年工作的熱心人、青年群眾的引路人
      </div>
    </div>
    <div class="indexContent">
      <div class="projectBlock">
        <div class="blockTitle">
          <span style="color: rgba(220, 112, 76, 1)">品牌</span
          ><span style="color: rgba(9, 21, 69, 1)">項目</span>
        </div>
        <div class="checkBtn" @click="toProject()">
          查看全部<img src="@/assets/rightIcon1.png" alt="" />
        </div>
        <div class="projectList">
          <div
            class="projectItem"
            v-for="(item, index) in projectList"
            :key="index"
            @click="openUrl(item,'project')"
          >
            <div class="logoHover">
              <img src="@/assets/logoHover.png" alt="" />
            </div>
            <div class="projectNum">
              <div class="numText">0{{ index + 1 }}</div>
              <div class="underline"></div>
            </div>
            <div class="projectInfo">
              <div class="projectTitle">{{ item.title }}</div>
              <div class="projectDes textOverflow">
                {{ item.description }}
              </div>
            </div>
            <div class="rightIcon">
              <img src="@/assets/rightIcon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 资讯模块 -->
      <div class="projectBlock">
        <div class="blockTitle">
          <span style="color: rgba(9, 21, 69, 1)">資訊</span>
        </div>
        <div class="checkBtn" @click="toNews()">
          查看全部<img src="@/assets/rightIcon1.png" alt="" />
        </div>
        <div class="newsLine">
          <div class="newsTypeItem" @click="toNews(1)">
            <img class="imgCut" src="@/assets/daily.png" alt="" />
            <div class="newsTypeText">晨日報</div>
          </div>
          <div class="newsTypeItem" @click="toNews(2)">
            <img class="imgCut" src="@/assets/tips.png" alt="" />
            <div class="newsTypeText">攻略與信息</div>
          </div>
          <div class="newsTypeItem" @click="toNews(3)">
            <img class="imgCut" src="@/assets/privacy.png" alt="" />
            <div class="newsTypeText">大灣區政策</div>
          </div>
        </div>
      </div>
      <!-- 文章报告模块 -->
      <div class="projectBlock">
        <div class="blockTitle">
          <span style="color: rgba(220, 112, 76, 1)">文章</span
          ><span style="color: rgba(9, 21, 69, 1)">報告</span>
        </div>
        <div class="checkBtn" @click="toArticle()">
          查看全部<img src="@/assets/rightIcon1.png" alt="" />
        </div>
        <div class="articleLine">
          <div
            class="indexArti"
            v-for="(item, index) in articleList"
            :key="index"
            @click="openUrl(item,'article')"
          >
            <div class="articleImg">
              <img class="imgCut" :src="item.image" alt="" />
              <div class="articleType">{{ typeMap[item.type] }}</div>
            </div>
            <div>
              <div class="title textOverflow">{{ item.title }}</div>
              <div class="des textOverflow">
                {{ item.description }}
              </div>
              <div class="writer">
                <div class="writerIcon"></div>
                <div class="authorList" v-if="item.authorList">
                  <span
                    v-for="(authorItem, index) in item.authorList"
                    :key="index"
                    >{{ authorItem.name }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      projectList: [],
      articleList: [],
      typeMap: {
        1: "文章",
        2: "報告",
      },
    };
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    document.title = "首頁 - 薈翔 Teglidan";
    this.getIndexInfo();
  },
  methods: {
    toArticle() {
      window.location.href = "/article";
    },
    toProject() {
      window.location.href = "/project";
    },
    toNews(tab) {
      if (!tab) {
        window.open("/news");
      } else window.open(`/news?tab=${tab}`);
    },
    openUrl(item,type) {
      if (item.isLink) {
        window.open(item.linkUrl);
      } else window.open(`/detail?type=${type}&id=${item.id}`);
    },
    async getIndexInfo() {
      const indexInfoRes = await request.get("/webapi/web/index/detail", {});
      this.projectList = indexInfoRes.data.brandProjectList;
      this.articleList = indexInfoRes.data.articleList;
    },
  },
};
</script>

<style lang="scss">
.scrollContainer {
  overflow: auto;
  height: 100vh;
}
.indexContent {
  background: url("https://teglidan.wareton.com/webimg/indexBack.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 0;
  background-position-x: 50%;
  padding-top: 1px;
}
.imgCut {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.isMobile {
  .indexContent {
    background: none;
  }
  .headImg {
    .headText {
      font-size: 16px;
      width: 70%;
    }
  }
  .projectBlock {
    margin: 30px 0;
  }
  .blockTitle {
    font-weight: 700;
    font-size: 20px;
  }
  .checkBtn {
    font-size: 12px;
    color: #dc704c;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 12px;
    }
  }
}
.headImg {
  position: relative;
  width: 100vw;
  img {
    width: 100vw;
    height: auto;
    object-fit: cover;
  }
  .headFilter {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(219, 117, 74, 0.1);
    top: 0;
  }
  .headText {
    position: absolute;
    font-weight: 700;
    font-size: 50px;
    line-height: 1.5;
    color: #fff;
    text-shadow: 0px 0px 10px #000000;
    text-align: center;
    top: 36.8%;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
  }
}
.projectBlock {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blockTitle {
  font-weight: 700;
  font-size: 60px;
}
.checkBtn {
  font-size: 20px;
  color: #dc704c;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 2;
  img {
    width: 20px;
  }
}
.textOverflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
.isMobile {
  .newsLine {
    margin: 22px 0 20px 0;
    .newsTypeItem {
      width: 88%;
      height: 268px;
      margin: 0 auto 16px auto;
      border-radius: 12px;
      .newsTypeText {
        font-size: 24px;
      }
    }
  }
  .articleLine {
    margin: 22px 0 30px 0;
    .indexArti {
      width: 88%;
      height: auto;
      display: flex;
      .articleImg {
        width: 168px;
        flex-shrink: 0;
        height: 90px;
        margin-right: 13px;
        border-radius: 12px;
        .articleType {
          right: -16px;
          top: -18px;
          padding: 20px 24px 3px 16px;
          border-radius: 24px;
          font-size: 11px;
          color: #fff;
          font-weight: 300;
        }
      }
      .title {
        font-size: 16px;
        line-height: 22px;
        height: 66px;
      }
      .title.textOverflow {
        -webkit-line-clamp: 3;
      }
      .des {
        display: none;
      }
    }
  }
  .projectList {
    .projectItem {
      width: 100%;
      height: 120px;
      margin: 8px 10px;
      img {
        height: 120px;
        left: 0;
      }
      .projectNum {
        margin: 0 16px;
        .numText {
          font-weight: 700;
          font-size: 34px;
          line-height: 47px;
          color: #dc704c;
        }
        .underline {
          background: #dc704c;
          border-radius: 2px;
          width: 39px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .projectInfo {
        height: auto;
        width: 100%;
        .projectTitle {
          font-weight: 700;
          font-size: 16px;
          line-height: 1.6;
        }
        .projectDes {
          margin-top: 4px;
          font-size: 12px;
          line-height: 1.7;
        }
      }
      .rightIcon {
        width: 14px;
        height: 14px;
        margin: 0 4px;
        flex-shrink: 0;
        img {
          width: 14px;
          height: 14px;
          display: block;
        }
      }
    }
  }
}
.newsLine {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 165px 0;
  flex-wrap: wrap;
  .newsTypeItem:hover .newsTypeText {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  }
  .newsTypeItem {
    width: 380px;
    height: 298px;
    margin: 15px;
    cursor: pointer;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    .newsTypeText {
      position: absolute;
      top: 34px;
      left: 34px;
      font-size: 32px;
      font-weight: 700;
      color: #fff;
      text-shadow: 0px 0px 8px #000000;
      transition: all 0.2s;
      white-space: nowrap;
    }
  }
}
.articleLine {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 36px 0 120px 0;
  flex-wrap: wrap;

  .indexArti {
    margin: 0 6px;
    width: 292px;
    height: 341px;
    cursor: pointer;
    color: #323232;
    .articleImg {
      width: 292px;
      height: 159px;
      margin-bottom: 14px;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      .articleType {
        position: absolute;
        right: -24px;
        top: -26px;
        background: #dc704c;
        padding: 32px 44px 6px 24px;
        border-radius: 44px;
        font-size: 14px;
        color: #fff;
        font-weight: 300;
      }
    }
    .title {
      font-weight: 700;
      font-size: 24px;
      color: #091545;
      line-height: 33px;
      margin-bottom: 6px;
    }
    .title.textOverflow {
      -webkit-line-clamp: 2;
    }
    .des {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 12px;
    }
    .writer {
      font-size: 14px;
      display: flex;
      line-height: 18px;
      .writerIcon {
        width: 3px;
        height: 18px;
        background: #dc704c;
        border-radius: 2px;
        margin-right: 4px;
      }
    }
  }
}
.projectList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  .projectItem {
    width: 582px;
    height: 165px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 18px 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .logoHover {
      position: absolute;
      left: 0;
      top: 0;
      display: none;
      img {
        height: 165px;
        left: 0;
      }
    }
    .projectNum {
      margin: 0 30px 0 32px;
      .numText {
        font-weight: 700;
        font-size: 34px;
        line-height: 47px;
        color: #dc704c;
      }
      .underline {
        background: #dc704c;
        border-radius: 2px;
        width: 39px;
        height: 6px;
        margin: 0 auto;
      }
    }
    .projectInfo {
      height: 108px;
      width: 100%;
      .projectTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #091545;
      }
      .projectDes {
        margin-top: 9px;
        font-size: 16px;
        line-height: 22px;
        color: #323232;
      }
    }
    .rightIcon {
      width: 24px;
      height: 24px;
      margin: 0 14px;
      flex-shrink: 0;
      img {
        width: 24px;
        display: none;
      }
    }
  }
  .projectItem:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    .logoHover {
      display: block;
    }
    .rightIcon {
      img {
        display: block;
      }
    }
  }
}
</style>
