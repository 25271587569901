<template>
  <div :class="{ orgTab: tab == 4 }"  class="scrollContainer">
    <Header />
    <div class="aboutBack"></div>
    <div class="aboutBlock">
      <div class="secondTitle">
        關於我們<img class="titleBack" src="@/assets/titleBack.png" alt="" />
      </div>
      <div class="tabLine about">
        <div class="tabItem" @click="tab = 1">
          <div>薈翔簡介</div>
          <div :class="{ selected: tab == 1 }"></div>
        </div>
        <div class="tabItem" @click="tab = 2">
          <div>永遠名譽會長致辭</div>
          <div :class="{ selected: tab == 2 }"></div>
        </div>
        <div class="tabItem" @click="tab = 3">
          <div>會長的話</div>
          <div :class="{ selected: tab == 3 }"></div>
        </div>
        <div class="tabItem" @click="tab = 4">
          <div>組織架構</div>
          <div :class="{ selected: tab == 4 }"></div>
        </div>
      </div>
      <div v-if="tab == 1">
        <div class="aboutList">
          <div
            v-for="(item, index) in aboutList"
            :key="index"
            class="aboutItem"
          >
            <div class="aboutItemTitle">
              <img :src="item.icon" alt="" />
              <div>{{ item.title }}</div>
            </div>
            <div class="aboutItemInfo">{{ item.des }}</div>
          </div>
        </div>
      </div>
      <div v-if="tab == 2" class="speech">
        <div class="speechTitle">
          香港廣州社團總會主席、薈翔永遠名譽會長<br />
          黃俊康先生致辭
        </div>
        <div class="speechContent">
          <span>尊敬的各位主禮嘉賓、各位青年朋友：</span>
          <p>
            大家好！今天香港廣州社團總會屬下機構薈翔舉行成立慶典，我僅代表香港廣州社團總會感謝各位領導在百忙之中蒞臨主禮。同時亦感謝各位來賓撥冗光臨，令此次慶典倍增光彩。
          </p>
          <p>
            薈翔是一個以凝聚和服務新港人青年為目標的旗幟鮮明的愛國愛港組織，在經歷了一九年黑暴事件和飽受疫情打擊後的今天成立，有著重大的意義。我們都知道，新港人，尤其是新港人青年，一直是推動香港發展的重要力量，他們自全國各地甚至世界各地來到這片香江熱土，發光發熱，這其中有像孫中山先生這樣經天緯地的民族英雄；有像莊世平先生、査濟民先生這樣白手起家的愛國企業家；也有像錢穆先生、查良鏞先生這樣的文化翹楚，當然，更多的是千千萬萬個可能默默無聞，但一直兢兢業業、默默奉獻的普通人，可以說，是他們塑造並成就了香港。
          </p>
          <p>
            今年是香港回歸祖國24週年，也是粵港澳大灣區和港深一體化等政策落地的關鍵年，更是習近平總書記提出的世界百年未有之大變局的轉折年，在此，我真誠的祝福並期望薈翔及其凝聚的新港人青年，能夠在這樣偉大的歷史時期中找到自己的定位、發揮自己的力量，並最終作出自己的貢獻，這是你們的時代。
          </p>
          <p>
            最後，我想用一百二十一年前梁啟超先生寫就的少年中國說中的一段話與各位共勉——少年智则国智，少年富则国富；少年强则国强，少年独立则国独立；少年自由则国自由；少年进步则国进步；少年胜于欧洲则国胜于欧洲；少年雄于地球则国雄于地球。红日初升，其道大光。
          </p>
          <p>再次多謝各位，祝願各位身體健康，事業進步。</p>
          <p class="speechFoot">黃俊康會長<br />2021年11月7日</p>
        </div>
      </div>
      <div v-if="tab == 3" class="speech">
        <div class="speechTitle">會長的話</div>
        <div class="speechContent">
          <p>
            「薈翔」二字是本會永遠名譽會長李鳳琼女士所起，寓意為薈萃青年英才，翱翔海闊天空，亦點明了本會的工作對象和工作任務。薈翔要做的，就是凝聚新港人中的青年才俊，並與他們一同發展，協助和陪伴他們翱翔在粵港澳大灣區乃至整個中國的廣闊天地之下，為香港的繁榮穩定、大灣區的共融發展甚至祖國的偉大復興作出我們應有的貢獻。
          </p>
          <p>
            薈翔堅決擁護「一國兩制」政策和「愛國者治港」等原則，堅定維護香港基本法、支持香港政府依法施政。
          </p>
          <p>
            我們將堅守薈翔的工作原則，即與時俱進、協同創新。本會將致力於打造「發現」、「發展」、「發聲」三個平台，通過調研分析、摸查需求，發現社會和青年痛點；以問題和需求為導向，設計落地服務項目；最後總結工作經驗、提煉工作成果，向政府機構建言獻策、向社會大眾傳遞正能量。
          </p>
          <p>
            今年是中華人民共和國成立72週年，是國家「十四五」規劃的開局之年，伴隨著《國安法》和「愛國者治港」的實施，香港迎來了融入國家發展大局、構建全新發展格局的新時代。本會將把握時代機遇，牢記使命，努力團結廣大有志青年，為祖國的發展大局貢獻力量，不愧青春！
          </p>
          <p class="speechFoot">楊雅雯會長<br />2021年11月7日</p>
        </div>
      </div>
      <div v-if="tab == 4" class="orgChart">
        <img src="https://teglidan.wareton.com/webimg/orgChart1.png" alt="" />
        <img src="https://teglidan.wareton.com/webimg/orgChart2.png" alt="" />
        <img src="https://teglidan.wareton.com/webimg/orgChart3.png" alt="" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      tab: 1,
      aboutList: [
        {
          icon: require("@/assets/aboutIcon1.png"),
          title: "成立",
          des: "薈翔於2020年6月15日在香港註冊成立，受廣州市委統戰部、香港廣州社團總會的指導。",
        },
        {
          icon: require("@/assets/aboutIcon2.png"),
          title: "會名",
          des: "「薈翔」寓意匯聚青年英才，各展其長，齊力翱翔於海闊天空；英文名「Teglidan」取自To Glide，寓意展翅高飛。",
        },
        {
          icon: require("@/assets/aboutIcon3.png"),
          title: "定位",
          des: "薈翔是一個凝聚港漂、團結港人的青年組織，通過調查研究分析社會和青年的實際問題與需求，以解決問題與需求為導向設計落地項目服務社會與青年。",
        },
        {
          icon: require("@/assets/aboutIcon4.png"),
          title: "宗旨",
          des: "薈翔旨在匯聚香港與內地之間跨境發展的青年英才，凝聚一幫有誌之士，助力粵港澳大灣區建設，推動香港融入國家發展大局，促進香港長期繁榮穩定。",
        },
        {
          icon: require("@/assets/aboutIcon5.png"),
          title: "會員",
          des: "執行委員會核心成員來自香港各行各業的青年精英。執行委員會下設六大工作委員會：學生團體委員會、創新創業委員會、專業人士委員會、文化宣傳委員會、社會服務委員會、國情交流委員會。截至2022年10月20日，薈翔吸納會員455名。",
        },
      ],
    };
  },
  async created() {
    console.log("about created");
    document.title = "關於我們 - 薈翔 Teglidan";
  },
};
</script>
<style>
.isMobile .tabLine.about::-webkit-scrollbar {
  display: none;
}
</style>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.isMobile {
  .aboutBack {
  background: url("~@/assets/aboutBack2.png");
  width: 100%;
  height: 300px;
  background-size: 100%;
  background-repeat: no-repeat;
}
  .tabLine.about {
    margin-bottom: 24px;
    justify-content: initial;
  }
  .speech {
    .speechTitle {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .speechContent {
      font-size: 16px;
    }
  }

  .aboutBlock {
    margin: -180px 0 0 0;
    padding-bottom: 60px;
  }
  .aboutList {
    .aboutItem {
      width: 90%;
      margin-bottom: 28px;
      .aboutItemTitle {
        font-size: 18px;
        margin-bottom: 6px;
        img {
          width: 24px;
          margin-right: 6px;
        }
      }
      .aboutItemInfo {
        font-size: 14px;
        margin-left: 2px;
      }
    }
  }
}
.orgTab {
  background: linear-gradient(
      180deg,
      rgba(220, 112, 76, 0) 0%,
      rgba(220, 112, 76, 0.2) 100%
    ),
    transparent;
}
.aboutBack {
  background: url("~@/assets/aboutBack.png");
  width: 100%;
  height: 1191px;
  background-size: cover cover;
  background-repeat: no-repeat;
  background-position-x: 50%;
}
.aboutBlock {
  margin: -1000px 0 0 0;
  padding-bottom: 100px;
}
.tabLine.about {
  margin-bottom: 48px;
}
.aboutList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .aboutItem {
    width: 95%;
    max-width: 1200px;
    margin-bottom: 36px;
    .aboutItemTitle {
      display: flex;
      align-items: center;
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 16px;
      img {
        width: 40px;
        margin-right: 8px;
      }
    }
    .aboutItemInfo {
      font-size: 18px;
      line-height: 1.5;
      margin-left: 4px;
    }
  }
}
.speech {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
  .speechTitle {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 36px;
  }
  .speechContent {
    color: #323232;
    font-size: 24px;
    line-height: 150%;
    p {
      text-indent: 2em;
    }
    .speechFoot {
      text-align: right;
    }
  }
}
.orgChart {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100vh;
  img {
    width: 100%;
    margin-bottom: 80px;
  }
}
</style>