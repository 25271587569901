<template>
  <div class="footerBlock">
    <!-- <img src="@/assets/footImg.png" alt="" /> -->
    <div class="contactInfo">
      <div class="infoLineLeft">
        <div class="footerTitle">聯絡我們</div>
        <div class="footerInfoLine">
          <div class="footerInfoItem">
            <div class="itemInfo">
              <b>通訊地址：</b>
              香港九龍旺角彌敦道707-713號銀高國際大廈3樓
            </div>
          </div>
          <div class="footerInfoItem">
            <div class="itemInfo"><b>電話：</b>(852)2336 1052</div>
          </div>
          <div class="footerInfoItem">
            <div class="itemInfo"><b>電郵：</b>teglidan@hkfga.com</div>
          </div>
        </div>
      </div>
      <div
        class="link"
        @click="openUrl('https://www.hkfga.com/page/index.html')"
      >
        <div class="linkTitle">友情链接</div>
        <img src="@/assets/linkImg.png" alt="" />
        <div class="linkText">香港廣州社團總會</div>
      </div>
    </div>
    <div class="footerInfo">
      <div class="infoLine">
        <div class="copyright infoText">
          Copyright © TEGLIDAN ASSOCIATION. All rights reserved.
        </div>
        <!-- <div class="link infoText">
          <a href="https://www.hkfga.com/page/index.html"
            >友情链接：香港廣州社團總會</a
          >
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss">
.isMobile {
  .footerBlock {
    .footerTitle {
      font-size: 20px;
    }
    .contactInfo {
      width: 90%;
      margin: 0 auto;
      padding: 26px 4px 0 4px;
      display: block;
      .infoLineLeft {
        .footerTitle {
          margin-bottom: 14px;
        }
      }
      .link {
        margin: 0;
        .linkTitle {
          font-size: 14px;
        }
        img {
          width: 60px;
          height: 60px;
          margin: 8px 0 2px 0;
        }
        .linkText {
          font-size: 16px;
        }
      }
    }
    .footerInfoLine {
      margin-bottom: 20px;
      .footerInfoItem {
        margin: 0 20px 12px 0;
        font-size: 14px;
        line-height: 1.5;
        
      }
    }
    .footerInfo {
      font-size: 11px;
      .infoLine {
        flex-wrap: wrap;
        width: 90%;
      }
    }
  }
}
.footerBlock {
  position: relative;
  height: 387px;
  width: 100%;
  background: url("~@/assets/footImg.png");
  background-size: cover;
  color: #fff;

  .contactInfo {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 90%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 52px 20px 0 20px;
    .infoLineLeft {
      margin-right: 20px;
    }
    .link {
      margin: 0 60px 0 auto;
      text-align: center;
      cursor: pointer;
      .linkTitle {
        font-size: 18px;
        font-weight: 300;
      }
      .linkText {
        font-size: 24px;
      }
      img {
        width: 110px;
        height: 110px;
        margin: 16px 0 6px 0;
      }
    }
  }
  .footerTitle {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 28px;
  }
  .footerInfoLine {
    .footerInfoItem {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 26px;
      .itemInfo {
        color: rgba(255, 255, 255, 0.8);
        font-weight: 300;
        b {
          color: #fff;
        }
      }
    }
    
  }
  .infoText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .footerInfo {
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(220, 112, 76, 0.5);
    backdrop-filter: blur(10px);
    .infoLine {
      display: flex;
      align-items: center;
      height: 52px;
      width: 90%;
      max-width: 1440px;
      margin: 0 auto;
    }
  }
}
</style>
