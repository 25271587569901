<template>
  <div class="articleItemBack" @click="openUrl(item)">
    <div class="articleItem">
      <div class="cover"><img v-if="item.image" class="imgCut" :src="item.image" alt="" /></div>
      <div class="info">
        <div v-if="item.title"  class="artiTitle textOverflow">{{ item.title }}</div>
        <div class="artiDes textOverflow" v-if="item.description" >
          {{ item.description }}
        </div>
        <div class="artiDate">
          <img src="@/assets/dateIcon.png" alt="" />
          <div class="dateText">{{ item.publishDate }}</div>
        </div>
      </div>
      <div class="orangeRight">
        <img src="@/assets/rightIcon_orange.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item","type"],
  methods: {
    openUrl(item) {
      if (item.isLink) {
        window.open(item.linkUrl);
      }
      else window.open(`/detail?type=${this.type}&id=${item.id}`)
    },
  },
};
</script>

<style lang="scss">
.isMobile {
  .articleItemBack {
    margin: 0 auto 20px auto;
    height: 90px;
  }
  .articleItem {
    padding: 0;
    font-size: 12px;
    .cover {
      width: 168px;
      height: 90px;
      margin-right: 13px;
      border-radius: 12px;
    }
    .artiTitle {
      font-size: 16px;
      line-height: 22px;
      height: 66px;
      margin-bottom: 6px;
    }
    .artiTitle.textOverflow {
      -webkit-line-clamp: 3;
    }
    .artiDes {
      display: none;
    }
    .artiDate {
      display: flex;
      color: #666666;
      align-items: center;
      img {
        width: 16px;
        margin-right: 2px;
      }
    }
    .orangeRight {
      display: none;
    }
  }
}
.articleItemBack {
  margin: 0 auto 20px auto;
  background: #fff;
  width: 90%;
  max-width: 1175px;
  height: 224px;
}
.articleItem {
  display: flex;
  cursor: pointer;
  border-radius: 20px;
  padding: 32px 38px;
  box-sizing: border-box;
  align-items: center;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  background: #fff;
  .cover {
    width: 292px;
    height: 159px;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 25px;
    flex-shrink: 0;
  }
  .artiTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #091545;
    margin-bottom: 14px;
  }
  .artiTitle.textOverflow {
    -webkit-line-clamp: 1;
  }
  .artiDes {
    color: #323232;
    height: 44px;
    margin: 0 42px 44px 0;
  }
  .artiDes.textOverflow {
    -webkit-line-clamp: 2;
  }
  .artiDate {
    display: flex;
    color: #666666;
    align-items: center;
    img {
      width: 24px;
      margin-right: 5px;
    }
  }
}
.articleItem:hover {
  background: linear-gradient(
    90deg,
    rgba(233, 29, 110, 0.1) 0%,
    rgba(217, 171, 74, 0.1) 27%,
    rgba(255, 255, 255, 0) 52.4%
  );
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
}
</style>