<template>
  <div id="app" :class="{ isMobile: isMobileWidth }">
    <router-view />
  </div>
</template>

<script>
import { debounce } from 'lodash-es'

export default {
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    isMobileWidth() {
      return this.windowWidth < 900
    }
  },
  provide() {
    return {
      getIsMobileWidth: this.getIsMobileWidth
    }
  },
  created() {
    window.addEventListener('resize', this.updateSize)
  },
  methods: {
    updateSize: debounce(function() {
      this.windowWidth = window.innerWidth
    }, 200),
    getIsMobileWidth() {
      return this.isMobileWidth
    }
  }
}
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
</style>
