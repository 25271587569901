<template>
  <div class="navigator">
    <div class="navInfo">
      <div class="navLeft" @click="toIndex()">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="navRight">
        <div
          class="navItem"
          :class="{ bold: path === 'Project' }"
          @click="toProject()"
        >
          品牌項目
        </div>
        <div
          class="navItem"
          :class="{ bold: path === 'News' }"
          @click="toNews()"
        >
          資訊
        </div>
        <div
          class="navItem"
          :class="{ bold: path === 'Article' }"
          @click="toArticle()"
        >
          文章報告
        </div>
        <div
          class="navItem"
          :class="{ bold: path === 'About' }"
          @click="toAbout()"
        >
          關於我們
        </div>
        <div
          class="navItem"
          @click="openUrl('https://wj.qq.com/s2/9487533/6083/')"
        >
          申請入會
        </div>
        <div class="mobileMoreBtn" @click="clickMore">
          <img v-if="!showMore" src="@/assets/btn_more.png" alt="" />
          <img v-else src="@/assets/btn_close.png" alt="">
        </div>
      </div>
      <div class="mobileMore" v-if="showMore">
        <div class="moreItem" :class="{ bold: path === 'Home' }" @click="toIndex()">
          首頁
        </div>
        <div
          class="moreItem"
          :class="{ bold: path === 'Project' }"
          @click="toProject()"
        >
          品牌項目
        </div>
        <div
          class="moreItem"
          :class="{ bold: path === 'News' }"
          @click="toNews()"
        >
          資訊
        </div>
        <div
          class="moreItem"
          :class="{ bold: path === 'Article' }"
          @click="toArticle()"
        >
          文章報告
        </div>
        <div
          class="moreItem"
          :class="{ bold: path === 'About' }"
          @click="toAbout()"
        >
          關於我們
        </div>
        <div class="moreItem" @click="openUrl('https://wj.qq.com/s2/9487533/6083/')">申請入會</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "",
      showMore: false,
    };
  },
  async created() {
    this.path = this.$route.name;
    // document.title = "關於我們 - 薈翔 Teglidan";
  },
  methods: {
    clickMore() {
      this.showMore = !this.showMore;
    },
    toArticle() {
      window.location.href = "/article";
    },
    toProject() {
      window.location.href = "/project";
    },
    toAbout() {
      window.location.href = "/about";
    },
    toIndex() {
      window.location.href = "/";
    },
    toNews() {
      window.location.href = "/news";
    },
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>


<style lang="scss">
.isMobile {
  .navigator {
    height: 44px;
    .navInfo {
      width: 100%;
      padding: 0 14px 0 4px;
      box-sizing: border-box;
      .navLeft {
        img {
          width: 90px;
        }
      }
      .navRight {
        .navItem {
          display: none;
        }
        .mobileMoreBtn {
          display: block;
        }
      }
      .mobileMore {
        display: block;
        background: #fff;
        top: 40px;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding: 20px 22px;
        box-sizing: border-box;
        .moreItem {
          padding: 14px;
          font-size: 14px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          color: #666666;
        }
        .bold {
          color: #dc704c;
          font-weight: 700;
        }
      }
    }
  }
}
.bold {
  color: #dc704c;
}
.navigator {
  height: 90px;
  width: 100%;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 999;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  .navInfo {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    max-width: 1440px;
    position: relative;
    .navLeft {
      cursor: pointer;
      margin-left: 0;
      img {
        width: 147px;
      }
    }
    .navRight {
      display: flex;
      margin: 0 0 0 auto;
      .navItem {
        margin-right: 48px;
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;
      }
      .mobileMoreBtn {
        display: none;
        img {
          width: 28px;
        }
      }
    }
    .mobileMore {
      display: none;
      position: absolute;
    }
  }
}
</style>