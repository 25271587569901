<template>
  <div class="secondPage scrollContainer">
    <Header />
    <div class="secondBlock">
      <div class="secondTitle">
        文章報告<img class="titleBack" src="@/assets/titleBack.png" alt="" />
      </div>
      <div class="tabLine">
        <div class="tabItem" @click="switchTab(1)">
          <div>作家專欄</div>
          <div :class="{ selected: tab == 1 }"></div>
        </div>
        <div class="tabItem" @click="switchTab(2)">
          <div>最新文章</div>
          <div :class="{ selected: tab == 2 }"></div>
        </div>
        <div class="tabItem" @click="switchTab(3)">
          <div>調研報告</div>
          <div :class="{ selected: tab == 3 }"></div>
        </div>
      </div>
      <div v-if="tab == 1">
        <div class="writerLine">
          <div
            class="writerItem"
            @click="clickWriter(item)"
            v-for="(item, index) in writerList"
            :key="index"
          >
            <div class="writerImg">
              <img class="imgCut" :src="item.image" alt="" />
            </div>
            <div
              class="writerName"
              :class="{ writerSelect: writer == item.id }"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="reportList" v-if="articleList.length">
          <div
            class="reportItem"
            @click="openUrl(item)"
            v-for="(item, index) in articleList"
            :key="index"
          >
            <div class="reportImg">
              <img class="imgCut" :src="item.image" alt="" />
            </div>
            <div class="reportTitle textOverflow">
              {{ item.title }}
            </div>
            <div class="reportDes textOverflow">
              {{ item.description }}
            </div>
            <div class="reportDate">
              <img src="@/assets/dateIcon.png" alt="" />
              <div class="dateText">{{ item.publishDate }}</div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="emptyText">抱歉，沒有查詢到相關信息！</div>
        </div>
      </div>
      <div v-if="tab == 2">
        <div class="articleList" v-if="articleList.length">
          <ArticleItem
            v-for="(item, index) in articleList"
            :key="index"
            :item="item"
            type="article"
          />
        </div>
        <div v-else>
          <div class="emptyText">抱歉，沒有查詢到相關信息！</div>
        </div>
      </div>
      <div v-if="tab == 3">
        <div class="articleList" v-if="articleList.length">
          <ArticleItem
            v-for="(item, index) in articleList"
            :key="index"
            :item="item"
            type="article"
          />
        </div>
        <div v-else>
          <div class="emptyText">抱歉，沒有查詢到相關信息！</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ArticleItem from "@/components/ArticleItem.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      tab: 1,
      writer: "",
      writerList: [],
      articleList: [],
    };
  },
  components: {
    Header,
    Footer,
    ArticleItem,
  },
  methods: {
    switchTab(tab) {
      this.tab = tab;
      if (tab == 1) {
        this.getAuthorList();
      }
      if (tab == 2) {
        this.getArticleList("", "1");
      }
      if (tab == 3) {
        this.getArticleList("", "2");
      }
    },
    openUrl(item) {
      if (item.isLink) {
        window.open(item.linkUrl);
      } else window.open(`/detail?type=article&id=${item.id}`);
    },
    clickWriter(item) {
      this.writer = item.id;
      this.getArticleList(item.id, "");
    },
    async getArticleList(id, type) {
      const authorListRes = await request.get("/webapi/web/article/list", {
        params: {
          authorId: id,
          type: type,
        },
      });
      this.articleList = authorListRes.data;
    },
    async getAuthorList() {
      const authorListRes = await request.get(
        "/webapi/web/article/getAuthorList",
        {}
      );
      this.writerList = authorListRes.data;
      this.writer = this.writerList[0].id;
      this.getArticleList(this.writer, "");
    },
  },
  async created() {
    document.title = "文章報告 - 薈翔 Teglidan";
    this.getAuthorList();
  },
};
</script>

<style lang="scss">
.isMobile {
  .writerLine {
    margin-top: 24px;
    .writerItem {
      margin: 0 14px;
      .writerImg {
        width: 60px;
        height: 60px;
      }
      .writerName {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
.articleList {
  margin: 36px auto 120px auto;
}
.writerLine {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  .writerItem {
    position: relative;
    cursor: pointer;
    margin: 0 24px;
    .writerImg {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      background: gray;
    }
    .writerName {
      margin-top: -15px;
      padding: 4px 10px;
      font-size: 16px;
      line-height: 22px;
      background: #f2f2f2;
      border-radius: 35px;
      position: relative;
    }
    .writerName.writerSelect {
      color: #dc704c;
      background: linear-gradient(180deg, #faf5eb 0%, #fce6ee 100%);
    }
  }
  .writerItem:hover {
    .writerName {
      color: #dc704c;
      background: linear-gradient(180deg, #faf5eb 0%, #fce6ee 100%);
    }
  }
}
</style>

<style lang="scss" scoped>
.isMobile {
  .reportList {
    width: 90%;
    margin: 24px auto 60px auto;
    .reportItem {
      width: 100%;
      height: auto;
      margin: 0 0 24px 0;
      .reportImg {
        width: 100%;
        height: 187px;
        margin-bottom: 12px;
      }
      .reportTitle {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 4px;
      }
      .reportDes {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 8px;
      }
      .reportDate {
        font-size: 12px;

        img {
          width: 16px;
          margin-right: 2px;
        }
      }
    }
  }
}
.reportList {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  width: 98%;
  max-width: 1200px;
  margin: 48px auto 120px auto;
  .reportItem {
    width: 370px;
    height: 405px;
    font-size: 16px;
    line-height: 22px;
    margin: 0 14px 50px 14px;
    cursor: pointer;
    .reportImg {
      width: 370px;
      height: 204px;
      border-radius: 26px;
      overflow: hidden;
      margin-bottom: 25px;
    }
    .reportTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #091545;
      margin-bottom: 6px;
    }
    .reportTitle.textOverflow {
      -webkit-line-clamp: 2;
    }
    .reportDes {
      color: #323232;
      margin-bottom: 16px;
    }
    .reportDate {
      color: #666666;
      display: flex;
      align-items: center;
      img {
        width: 24px;
        margin-right: 5px;
      }
    }
  }
}
</style>