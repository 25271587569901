<template>
  <div class="scrollContainer">
    <Header />
    <div class="secondBlock">
      <div class="secondTitle">
        資訊<img class="titleBack" src="@/assets/titleBack.png" alt="" />
      </div>
      <div class="tabLine">
        <div class="tabItem" @click="switchTab(1)">
          <div>晨日報</div>
          <div :class="{ selected: tab == 1 }"></div>
        </div>
        <div class="tabItem" @click="switchTab(2)">
          <div>攻略與訊息</div>
          <div :class="{ selected: tab == 2 }"></div>
        </div>
        <div class="tabItem" @click="switchTab(3)">
          <div>大灣區政策</div>
          <div :class="{ selected: tab == 3 }"></div>
        </div>
      </div>
      <div class="dailyList" v-if="tab == 1">
        <div
          v-for="(item, index) in dailyList"
          :key="index"
          class="dailyItemBack"
          @click="openUrl(item)"
        >
          <div class="dailyItem">
            <div>{{ item.title }}</div>
            <div class="orangeRight">
              <img src="@/assets/rightIcon_orange.png" alt="" />
            </div>
          </div>
        </div>
        <div class="emptyText" v-if="empty3">抱歉，沒有查詢到相關信息！</div>
      </div>
      <div v-if="tab == 2" style="margin-bottom: 110px">
        <div class="searchBar">
          <input
            type="text"
            placeholder="搜索标题关键字..."
            v-model="keywords1"
            @input="search(tab, keywords1)"
          />
          <div class="searchIcon">
            <img src="@/assets/searchIcon.png" alt="" />
          </div>
        </div>
        <div class="articleList" v-if="!empty1">
          <ArticleItem
            v-for="(item, index) in tipsList"
            :key="index"
            :item="item"
            type="news"
          />
        </div>
        <div class="emptyText" v-if="empty1">抱歉，沒有查詢到相關信息！</div>
        <!-- <div class="newsListTitle">薈政策</div> -->
        <!-- <div class="newsList">
          
          <div
            class="newsItem dailyItem"
            v-for="item in newsList3"
            :key="item.url"
            @click="openUrl(item.url)"
          >
            <div class="textOverflow">{{ item.title }}</div>
            <div class="orangeRight"></div>
          </div>
        </div> -->
      </div>
      <div v-if="tab == 3">
        <template>
          <Map @selectHandler="clickCity" />
          <div class="cityList">
            <div
              class="cityTab"
              v-for="(item, index) in cityTabList"
              :key="index"
              :class="{ selectedCity: cityTab == item.value }"
            >
              <div v-if="index != 0" class="yLine"></div>
              <div class="cityText" @click="clickCity(item.value)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </template>
        <div class="searchBar">
          <input
            type="text"
            placeholder="搜索..."
            v-model="keywords2"
            @input="search(tab, keywords2)"
          />
          <div class="searchIcon">
            <img src="@/assets/searchIcon.png" alt="" />
          </div>
        </div>
        <div style="margin-top: 0" class="articleList" v-if="!empty2">
          <ArticleItem
            v-for="(item, index) in privacyList"
            :key="index"
            :item="item"
            type="news"
          />
        </div>
        <div class="emptyText" v-if="empty2">抱歉，沒有查詢到相關信息！</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Map from "@/components/Map.vue";
import ArticleItem from "@/components/ArticleItem.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      empty1: false,
      empty2: false,
      empty3: false,
      keywords1: "",
      keywords2: "",
      cityTabList: [
        {
          name: "全部",
          value: "",
        },
        {
          name: "香港",
          value: "hongkong",
        },
        {
          name: "澳門",
          value: "macao",
        },
        {
          name: "廣州",
          value: "guangzhou",
        },
        {
          name: "深圳",
          value: "shenzhen",
        },
        {
          name: "珠海",
          value: "zhuhai",
        },
        {
          name: "佛山",
          value: "foshan",
        },
        {
          name: "惠州",
          value: "huizhou",
        },
        {
          name: "東莞",
          value: "dongguan",
        },
        {
          name: "中山",
          value: "zhongshan",
        },
        {
          name: "江門",
          value: "jiangmen",
        },
        {
          name: "肇慶",
          value: "zhaoqing",
        },
      ],
      tab: 1,
      cityTab: "",
      dailyList: [],
      tipsList: [],
      privacyList: [],
      pageNum: 1,
    };
  },
  components: {
    Header,
    Footer,
    Map,
    ArticleItem,
  },
  async created() {
    document.title = "資訊 - 薈翔 Teglidan";
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
    this.getDailyList();
  },
  methods: {
    switchTab(tab) {
      this.tab = tab;
      this.keywords1 = ""
      this.keywords2 = ""
      if (tab == 1) {
        this.getDailyList();
      }
      if (tab == 2) {
        this.getTipsList(this.keywords1);
      }
      if (tab == 3) {
        this.cityTab = "";
        this.getPrivacyList("", this.keywords2);
      }
    },
    async getPrivacyList(city, keywords) {
      const privacyListRes = await request.get("/webapi/web/information/list", {
        params: {
          city: city,
          type: 3,
          text: keywords,
        },
      });
      this.privacyList = privacyListRes.data;
      if (!this.privacyList.length) {
        this.empty2 = true;
      } else this.empty2 = false;
    },
    async getTipsList(keywords) {
      this.empty1 = false;
      const tipsListRes = await request.get("/webapi/web/information/list", {
        params: {
          type: 2,
          text: keywords,
        },
      });
      this.tipsList = tipsListRes.data;
      if (!this.tipsList.length) {
        this.empty1 = true;
      } else this.empty1 = false;
    },
    async getDailyList() {
      this.empty3 = false;
      const dailyListRes = await request.get("/webapi/web/information/list", {
        params: {
          type: 1,
        },
      });
      this.dailyList = dailyListRes.data;
      if (!this.dailyList.length) {
        this.empty3 = true;
      } else this.empty3 = false;
    },
    openUrl(item) {
      if (item.isLink) {
        window.open(item.linkUrl);
      }
      else window.open(`/detail?type=news&id=${item.id}`)
    },
    clickCity(city) {
      console.log(city);
      this.cityTab = city;
      this.getPrivacyList(city, this.keywords2);
    },
    search: debounce(function (tab, keywords) {
      if (tab == 2) {
        console.log(2, keywords);
        this.getTipsList(keywords)
      } else if (tab == 3) {
        this.getPrivacyList(this.cityTab, keywords)
      }
    }, 200),
  },
};
</script>

<style lang="scss">
.emptyText {
  padding: 130px 0 340px 0;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
}
input ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #969696;
}
.isMobile {
  .orangeRight {
    width: 20px;
    height: 20px;
    img {
      width: 20px;
    }
  }
  .secondTitle {
    margin-bottom: 16px;
  }
  .tabLine {
    font-size: 18px;
    .tabItem {
      margin: 0 16px;
      padding: 18px 0;
      .selected {
        height: 8px;
        bottom: 0;
      }
    }
  }
}
.secondTitle {
  margin-bottom: 40px;
}
.tabLine {
  display: flex;
  font-size: 38px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: auto;
  .tabItem {
    margin: 0 40px;
    position: relative;
    padding: 25px 0;
    cursor: pointer;
    flex-shrink: 0;
    .selected {
      position: absolute;
      width: 102%;
      left: 50%;
      transform: translateX(-50%);
      height: 10px;
      background: #dc704c;
      border-radius: 2px;
      bottom: 0;
    }
  }
}
.orangeRight {
  width: 34px;
  height: 34px;
  margin-left: auto;
  img {
    width: 34px;
  }
}
</style>

<style lang="scss" scoped>
.isMobile {
  .searchBar {
    margin: 24px auto;
    width: 90%;
    input {
      padding: 12px 16px 12px 8px;
      font-size: 16px;
    }
    .searchIcon {
      top: 6px;
      right: 4px;
      img {
        width: 18px;
      }
    }
  }
  .dailyList {
    margin: 20px auto 60px auto;
  }
  .dailyItemBack {
    margin-bottom: 12px;
  }
  .dailyItem {
    border-radius: 8px;
    height: 40px;
    font-size: 14px;
    padding: 0 12px;
  }
  .newsList {
    margin: 24px auto;
    .newsListTitle {
      font-size: 16px;
      margin-bottom: 12px;
    }
    .newsItem {
      height: 40px;
    }
  }
  .cityList {
    width: 96%;

    .cityTab {
      font-size: 16px;
      margin-bottom: 12px;
      .cityText {
        padding: 0 12px;
      }
      .yLine {
        height: 12px;
      }
    }
  }
}

.dailyList {
  margin: 66px auto 120px auto;
  width: 90%;
  max-width: 1150px;
}
.dailyItemBack {
  background: #fff;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 20px;
}
.dailyItem {
  width: 100%;
  border-radius: 20px;
  height: 80px;
  font-size: 24px;
  padding: 0 42px 0 67px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dailyItem:hover {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  background: linear-gradient(
    90deg,
    rgba(233, 29, 110, 0.1) 0%,
    rgba(217, 171, 74, 0.1) 27%,
    rgba(255, 255, 255, 0) 52.4%
  );
}
.searchBar {
  margin: 55px auto 36px auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  input {
    border: none;
    box-sizing: border-box;
    padding: 18px 44px 18px 20px;
    color: #323232;
    font-size: 20px;
    width: 100%;
  }
  .searchIcon {
    position: absolute;
    right: 14px;
    top: 12px;
    padding: 6px 12px;
    cursor: pointer;
    img {
      width: 22px;
    }
  }
}
// .searchBar:focus-within {
//   border: 1px solid #dc704c;
// }
input:focus-visible {
  outline: none;
}
.cityList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 98%;
  max-width: 1200px;
  margin: 0 auto;
  .selectedCity.cityTab {
    color: #dc704c;
  }
  .cityTab {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    .yLine {
      width: 1px;
      height: 24px;
      background: rgba(0, 0, 0, 0.4);
    }
    .cityText {
      padding: 0 18px;
      cursor: pointer;
    }
  }
}
.newsList {
  margin: 66px auto 50px auto;
  width: 90%;
  max-width: 1150px;
  .newsListTitle {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 44px;
  }
  .newsItem {
    height: 100px;
    .textOverflow {
      width: 90%;
      -webkit-line-clamp: 1;
      text-align: left;
    }
  }
}
</style>