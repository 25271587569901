import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
	baseURL: 'https://teglidan.wareton.com',
	timeout: 15 * 1000 // 15s超时
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?'
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName]
        var part = encodeURIComponent(propName) + '='
        if (value !== null && typeof (value) !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              const params = propName + '[' + key + ']'
              var subPart = encodeURIComponent(params) + '='
              url += subPart + encodeURIComponent(value[key]) + '&'
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // 如果定制代码不是200，则判定为错误。
    if (res.code !== 200 && res.code !== 10003) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000,
        dangerouslyUseHTMLString: true,
      })
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
		// Message({
		//   message: error.msg?error.msg:'网络异常，请重试或联系管理员',
		//   type: 'error',
		//   duration: 5 * 1000
		// })

		if (error.message.indexOf('timeout') !== -1 && error.isAxiosError) {
			// 超时处理
			Vue.prototype.$message.error('接口超时')
		}
    return Promise.reject(error)
  }
)

export default service
